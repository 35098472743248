<template>
  <div class="window-height window-width row justify-center items-center">
    <q-spinner-clock color="primary" size="10em"/>
  </div>
</template>

<script>
export default {
  name: 'SpinnerStart'
}
</script>
