import {createRouter, createWebHistory} from 'vue-router'
import {routeGuard} from '../auth'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/synchronfirmen',
    name: 'Synchronfirmen',
    component: () => import(/* webpackChunkName: "synchronfirmen" */ '../views/Synchronfirmen.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/synchronfirma/:id',
    name: 'Synchronfirma',
    component: () => import(/* webpackChunkName: "synchronfirmen" */ '../views/Synchronfirma.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/film/:id/synchronfirma',
    name: 'FilmSynchronfirma',
    component: () => import(/* webpackChunkName: "synchronfirmen" */ '../views/EditFilmSynchronfirma.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/film/:id/dialogbuch',
    name: 'FilmSynchronfirmaDialogbuch',
    component: () => import(/* webpackChunkName: "personen" */ '../views/EditFilmDialogbuch.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/film/:id/dialogregie',
    name: 'FilmSynchronfirmaDialogregie',
    component: () => import(/* webpackChunkName: "personen" */ '../views/EditFilmDialogregie.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/film/legacy',
    name: 'FilmeLegacy',
    component: () => import(/* webpackChunkName: "legacy" */ '../views/FilmeLegacy.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/serie/:id/synchronfirma',
    name: 'SerieSynchronfirma',
    component: () => import(/* webpackChunkName: "synchronfirmen" */ '../views/EditSerieSynchronfirma.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/serie/:id/dialogbuch',
    name: 'SerieSynchronfirmaDialogbuch',
    component: () => import(/* webpackChunkName: "personen" */ '../views/EditSerieDialogbuch.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/serie/:id/dialogregie',
    name: 'SerieSynchronfirmaDialogregie',
    component: () => import(/* webpackChunkName: "personen" */ '../views/EditSerieDialogregie.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/serie/legacy',
    name: 'SerieLegacy',
    component: () => import(/* webpackChunkName: "legacy" */ '../views/SerienLegacy.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/wizard/recherche',
    name: 'WizardRecherche',
    component: () => import(/* webpackChunkName: "wizard" */ '../views/WizardRecherche.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/personen',
    name: 'Personen',
    component: () => import(/* webpackChunkName: "personen" */ '../views/Personen.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/person/:id',
    name: 'Person',
    component: () => import(/* webpackChunkName: "personen" */ '../views/Person.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('../views/Callback.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
