import axios from "axios";
import config from "@/config";

const getUserProfile = async () => {
  let error = null
  let user = null
  try {
    const {data} = await axios.get(`https://${config.auth0.domain}/userinfo`);
    let info = await axios.get(`${config.api.url}/userProfile`);
    user = {
      user: data,
      info: info.data.data,
    }
  } catch (e) {
    error = e
  }

  return {user, error}
}

const useGetUserProfile = () => {
  return {getUserProfile}
}

export default useGetUserProfile
