<template>
  <q-header elevated class="glossy">
    <q-toolbar>
      <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="menu"/>

      <q-toolbar-title>
        Deutsche Synchronkartei &mdash; Administration
      </q-toolbar-title>

      <div v-if="!$auth.loading.value">
        <q-btn v-if="$auth.isAuthenticated.value" align="right" color="secondary" icon="power_settings_new"
               @click="logout" title="Ausloggen"/>
        <q-btn v-if="!$auth.isAuthenticated.value" align="right" color="positive" icon="login"
               @click="login" title="Einloggen"/>
      </div>
    </q-toolbar>
  </q-header>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },

    logout() {
      const logoutRoute = this.$router.getRoutes().find(r => r.name === 'Logout')
      this.$auth.logout({
        returnTo: window.location.origin + logoutRoute.path
      })
    }
  }
}
</script>
