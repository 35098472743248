<template>
  <q-item :to="to" clickable v-ripple>
    <q-item-section avatar>
      <q-icon :name="icon"/>
    </q-item-section>
    <q-item-section>{{ name }}</q-item-section>
  </q-item>
</template>

<script>
export default {
  name: 'MenuItem',
  props: ['name', 'icon', 'to'],
}
</script>
