export default {
  auth0: {
    domain: process.env.VUE_APP_SEIYUU_AUTH0_DOMAIN,
    client_id: process.env.VUE_APP_SEIYUU_AUTH0_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_SEIYUU_AUTH0_REDIRECT_URI,
    cacheLocation: process.env.VUE_APP_SEIYUU_AUTH0_CACHE_LOCATION,
    scope: "openid profile email",
    audience: "https://rest.synchronkartei.de"
  },
  api: {
    url: process.env.VUE_APP_SEIYUU_API_URL,
    test: process.env.VUE_APP_NOT_SECRET_CODE,
  },
  live: {
    mainSite: process.env.VUE_APP_SEIYUU_LIVE_MAIN_SITE,
  },
}
