import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

import config from './config'
import setupAxios from "@/config/axios";
import {setupAuth} from './auth'

const app = createApp(App).use(Quasar, quasarUserOptions).use(router)

// enable performance measuring except on production
//app.config.performance = process.env.NODE_ENV !== "production";

function callbackRedirect(appState) {
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  )
}

setupAuth(config.auth0, callbackRedirect).then((auth) => {
  setupAxios()
  app.use(auth).mount('#app')
})
