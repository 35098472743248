<template>
  <div class="window-height window-width row justify-center items-center">
    <q-banner inline-actions class="text-white bg-warning rounded-borders">
      Dieser Bereich ist nur für Redakteure.
      <template v-slot:action>
        <q-btn flat color="white" label="Zur Hauptseite" @click="redirectMain"/>
      </template>
    </q-banner>
  </div>
</template>

<script>
export default {
  name: 'ErrorBannerRedakteure',
  setup() {
    return {
      redirectMain: () => {
        window.location.href = "https://www.synchronkartei.de"
      }
    }
  },
}
</script>
