import './styles/quasar.sass'
import lang from 'quasar/lang/de.js'
import '@quasar/extras/material-icons/material-icons.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      primary: '#009292',
      secondary: '#333333',
      accent: '#b00c84',

      dark: '#1d1d1d',

      positive: '#5cb85c',
      negative: '#d9534f',
      info: '#5bc0de',
      warning: '#f0ad4e'
    },
    notify: {}
  },
  plugins: {
    Dialog,
    Notify,
  },
  lang: lang
}
